<template>
  <div
    class="user-list-footer d-flex justify-content-center align-items-center"
  >
    <div class="line" />
    <div class="content mx-2">
      <span
        v-if="showMoreButtonText != null"
        class="option btn btn-sm btn-dark mb-0"
        @click.prevent="$emit('show-more-button-pressed')"
      >
        {{ showMoreButtonText }}
      </span>
      <span 
        v-if="showMoreButtonText != null && showAllButtonText != null"
        class="mx-2">|</span>
      <span
        v-if="showAllButtonText != null"
        class="option btn btn-sm btn-dark mb-0"
        @click.prevent="$emit('show-all-button-pressed')"
      >
        {{  showAllButtonText }}
      </span>
    </div>
    <div class="line" />
  </div>
</template>

<script>
export default {
  props: {
    showMoreButtonText: { 
      type: [ String, null ],
      default: null
    },

    showAllButtonText: {
      type: [ String, null ], 
      default: null
    }
  },

  emits: [
    'show-more-button-pressed',
    'show-all-button-pressed'
  ]
}
</script>

<style>
.user-list-footer {
  .line {
    height: 0px;
    flex-grow: 1;
    border-top: 2px dotted var(--bs-body-color);
  }
  .option {
    cursor: pointer;
  }
}
</style>