<template>
  <basic-card>
    <template #header>
      <h5>Algemeen</h5>
    </template>
    
    <material-input
      id="group-name"
      v-model="group.title"
      label="Naam groep"
    />

    <template #footer>
      <material-button
        class="float-end mb-0"
        color="dark"
        variant="gradient"
        size="sm"
        @click="submit"
      >
        Update gegevens
      </material-button>
    </template>
  </basic-card>

  <basic-card
    class="mt-4"
  >
    <template #header>
      <h5>Leden</h5>
    </template>

    <div class="row">
      <div class="col-md-3 mb-n3">
        <dropdown 
          label="Gebruiker toevoegen" 
          :options="options"
          placeholder-label="Selecteer gebruiker"
          @update:value="addMember"
        />
      </div>
      <div class="col-md-9 d-flex justify-content-end align-items-end">
        <div class="d-flex flex-column">
          <label>Filters</label>
          <group-member-filters
            v-model="filteredMembers"
            :show-filters="true"
            :members="group.users"
          />
        </div>
      </div>
    </div>
  </basic-card>

  <div
    class="grid grid4 mt-5 px-0"
  >
    <div
      v-for="member in memberListSlice"
      :key="member.userId"
      class="grid-item pb-3"
    >
      <group-member-card
        :member="member"
        @removed="onMemberRemoved"
      />
    </div>
  </div>

  <p
    v-if="filteredMembers.length < 1"
    class="text-center mt-0"
  >
    Geen resultaten
  </p>

  <list-cutoff
    v-if="filteredMembers.length > displayedUserCount"
    :show-more-button-text="`Toon volgende ${userBatchSize} leden`"
    show-all-button-text="Toon alle leden"
    @show-more-button-pressed="showNextBatch"
    @show-all-button-pressed="showAllItems"
  />

  <div class="container-fluid">
    <div class="w-25 float-end">
      <material-button
        class="float-end mb-0"
        color="dark"
        variant="gradient"
        size="sm"
        @click="submit"
      >
        Update gegevens
      </material-button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import BasicCard from '@/components/UI/BasicCard.vue';
import Dropdown from '@/components/UI/Dropdown.vue';
import GroupMemberCard from '@/components/Groups/Edit/GroupMemberCard.vue';
import GroupMemberFilters from '@/components/Groups/Edit/Filters.vue';
import ListCutoff from '@/components/UI/ListCutoff.vue';
import MaterialButton from '@/components/MaterialButton.vue';
import MaterialInput from '@/components/MaterialInput.vue';

import { getAll as getAllUsers } from '@/api/providers/users';
import { getGroup, addGroup, editGroup } from '@/api/providers/groups';

export default {
  components: {
    BasicCard,
    Dropdown,
    GroupMemberCard,
    GroupMemberFilters,
    ListCutoff,
    MaterialButton,
    MaterialInput
  },

  data() {
    return {
      group: {
        id: null,
        title: '',
        users: [],
      },

      filterResults: undefined,

      displayedUserCount: 24,
      userBatchSize: 60,
      isNewGroup: false,

      options: [],
      apiUsers: []
    }
  },

  computed: {
    filteredMembers: {
      get() { 
        return (this.filterResults ?? this.group.users)
        .filter(u => !u.isDeleted);
      },
      set(value) { 
        this.filterResults = value;
      }
    },
    
    memberListSlice() {
      return this.filteredMembers
        .slice(0, this.displayedUserCount);
    },

    newUserIds() {
      return this.group.users
        .filter(u => u.isNew && !u.isDeleted)
        .map(u => u.id);
    },

    removedUserIds() {
      return this.group.users
        .filter(u => u.isDeleted && !u.isNew)
        .map(u => u.id);
    }
  },

  async mounted() {
    this.loadGroup();
    this.loadDropdown();
  },

  methods: {
    ...mapActions(
      'notification',
      { 
        addNotification: 'add',
        addError: 'addError'
      }
    ),

    showNextBatch() {
      this.displayedUserCount += this.userBatchSize;
    },

    showAllItems() {
      this.displayedUserCount = Infinity;
    },

    addMember(userId) {
      const existingUserIndex = this.group.users.findIndex(u => u.id == userId);
      if (existingUserIndex > -1) return;

      const index = this.apiUsers.findIndex(u => u.id == userId);
      if (index < 0) return;

      this.group.users.push({ ...this.apiUsers[index], isNew: true });
    },

    onMemberRemoved(id) {
      const index = this.group.users.findIndex(m => m.id === id);
      if (index < 0) return;

      this.group.users[index] = { ...this.group.users[index], isDeleted: true };
    },

    async loadGroup() {
      if (this.$route.params.id === undefined) {
        this.isNewGroup = true;
        return;
      }

      try {
        const group = await getGroup(this.$route.params.id);
        this.group = group;
      }
      catch {
        this.addError('Kon gegevens niet ophalen');
      }
    },

    async loadDropdown() {
      try {
        this.apiUsers = await getAllUsers();
        this.options = this.apiUsers.map(u => {
          return {
            label: u.fullName,
            value: u.id,
            image: u.profilePicture
          };
        });
      }
      catch {
        this.addError('Kon gebruikers niet ophalen');
        this.apiUsers = [];
        this.options = [];
      }
    },

    async submit() {
      try {
        this.isNewGroup
          ? await addGroup(
            this.group.title,
            this.newUserIds
          )
          : await editGroup(
            this.group.id,
            this.group.title,
            this.newUserIds,
            this.removedUserIds
          );

          this.addNotification({
            title: this.group.title,
            description: 'Wijzigingen opgeslagen',
            icon: { component: 'diversity_3' }
          });

          this.$router.push('/groups');
      }
      catch {
        this.addError('Kon wijzigingen niet opslaan.')
      }
    },
  }
}
</script>